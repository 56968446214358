import React, {useEffect, useState } from 'react'
import { BlockTitle, Icon } from '../../../components/Component';
import { axiosPrivate } from '../../../utils/Utils';
import { getLeadSourceLogs } from '../../../api/activities/use-cases/getLeadSourceLogs';
import { format, parseISO } from 'date-fns';

function LeadSourceLog({ lead }) {

  const [leadSourceLog, setLeadSourceLog] = useState([]);

  useEffect(() => {
    const fetchLeadSourceLogs = async () => {
      try {
        const payload = { "user": lead.id };

        const leadSourceLogs = await getLeadSourceLogs(axiosPrivate, payload);
        
        // Check if data exists before updating state
        if (leadSourceLogs && leadSourceLogs.data) {
          setLeadSourceLog(leadSourceLogs.data);
        }
      } catch (error) {
        console.error("Error fetching lead source logs:", error);
      }
    };

    fetchLeadSourceLogs();
  }, [lead.id]); 


  return (
    <>
      <div >
        <div className="card-title-group">
          <BlockTitle tag="h4" className="mt-5">Lead Source Change Log</BlockTitle>
      
        </div>
      </div>
      <div className="card-inner">
        <div className="timeline-horizontal">
          <ul className="timeline-horizontal-list">
            {leadSourceLog.length > 0 ? leadSourceLog.map((item,index) => (
              <li className="timeline-horizontal-item" key={item.id}>
                <div className={`timeline-horizontal-status bg-primary ${index==0? "is-outline" : ""}`}></div>
                <div className="timeline-horizontal-date">
                  {format(parseISO(item.createdAt), "dd/MMM/yyyy hh:mm a")} <Icon name="alarm-alt"></Icon>
                </div>
                <div className="timeline-horizontal-data">
                  <h6 className="timeline-horizontal-title">{item.newLeadSource.name}</h6>

                  <div className="timeline-horizontal-des">
                    <p>from</p>
                    <p>{item.oldLeadSource.name}</p>
                    <span className="time">
                      {item?.changedBy ?
                        `By ${item.changedBy.first_name} ${item.changedBy.last_name}` :
                        "By System User"}
                    </span>
                  </div>
                </div>
              </li>
            )): (<p>No Lead source log found</p>)}
          </ul>
        </div>

      </div>

    </>
  )
}

export default LeadSourceLog